import React from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'

const INTEGRATIONS = [{ label: 'Product Reviews', value: 'product_reviews' }]

function Plan({ plan, onPlanSwitch, usageBasedPlans, siteMetadata }) {
  return (
    <div className="pricing-plan">
      <div className="pricing-plan__section">
        <h3>{plan.name}</h3>
        <div>
          <span className="pricing-plan__currency">$</span>
          <span className="pricing-plan__value">{plan.price}</span>
          <span className="pricing-plan__duration">/mo</span>
        </div>
        {plan.isUnlimited && (
          <div className="pricing-plan__price-info">
            Plus <strong>{plan.revenuePercent}%</strong> of attributed revenue
            <br />
            (capped at <strong>${plan.maximumPrice}/mo</strong>)
          </div>
        )}
      </div>
      <div className="pricing-plan__section">
        <h4>{plan.isFree ? 'Email Support' : '24x7 Support'}</h4>
      </div>
      <div className="pricing-plan__section">
        <div className="pricing-plan__widget-count-wrap">
          {onPlanSwitch && (
            <span
              className={'pricing-plan__switch'}
              onClick={() => onPlanSwitch(-1)}
              onClick={() => onPlanSwitch(-1)}
              disabled={usageBasedPlans.indexOf(plan) === 0}
            >
              -
            </span>
          )}
          <span className="pricing-plan__widgets-count">
            {plan.maximumRecommendationsServedFormatted}
          </span>
          {onPlanSwitch && (
            <span
              className={'pricing-plan__switch'}
              onClick={() => onPlanSwitch(1)}
              disabled={
                usageBasedPlans.indexOf(plan) === usageBasedPlans.length - 1
              }
            >
              +
            </span>
          )}
        </div>
        <p>Widgets Served</p>
        {onPlanSwitch && false && (
          <p className="pricing-plan__cap-info">
            *Once usage exceeds plan quota, you will be charged{' '}
            <strong>
              ${plan.recommendationServesBatchPrice} per{' '}
              {plan.recommendationServesBatchSizeFormatted}
            </strong>{' '}
            widget serves. This additional charge is capped at{' '}
            <strong>${plan.maxPlanUsageCharge}/mo.</strong> Maximum widget serve
            threshold{' '}
            <strong>{plan.recommendationsServedHardLimitFormatted}</strong>
          </p>
        )}
      </div>
      <div className="pricing-plan__section">
        <h4>All Recommendation Types</h4>
      </div>
      <div className="pricing-plan__section">
        <h4>Integrations Enabled</h4>
        <ul className="pricing-plan__integrations">
          {INTEGRATIONS.map(integration => {
            return (
              <li
                key={integration.value}
                disabled={
                  plan.integrationsEnabled.indexOf(integration.value) === -1
                }
              >
                {integration.label}
              </li>
            )
          })}
        </ul>
      </div>
      <div className="pricing-plan__section">
        <a
          href={`${siteMetadata.shopifyUrl}?plan=${plan.slug}`}
          className={classNames('install-link', {
            ['install-link--solid']: plan.isFree,
            ['install-link--bordered']: !plan.isFree
          })}
        >
          {plan.isFree ? 'Install For Free' : `Install Risk Free`}
        </a>
      </div>
    </div>
  )
}

function Pricing() {
  const {
    allPlansJson,
    site: { siteMetadata }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            shopifyUrl
          }
        }
        allPlansJson {
          edges {
            node {
              id
              name
              enabled
              isUnlimited
              isFree
              slug
              integrationsEnabled
              price
              maximumRecommendationsServedFormatted
              revenuePercent
              maximumPrice
              maxPlanUsageCharge
              recommendationServesBatchPrice
              recommendationServesBatchSizeFormatted
              recommendationsServedHardLimitFormatted
              integrationsEnabled
            }
          }
        }
      }
    `
  )

  const activePlans = allPlansJson.edges
    .filter(({ node: plan }) => plan.enabled)
    .map(({ node }) => node)
  const freePlan = activePlans.filter(plan => plan.isFree)[0]
  const unlimitedPlan = activePlans.filter(plan => plan.isUnlimited)[0]

  return (
    <div id="pricing" className={'pricing-wrap'}>
      <h2>Simple Pricing</h2>
      <div className={'pricing-plans'}>
        {activePlans
          .filter(k => !!k)
          .map(plan => (
            <Plan plan={plan} key={plan.id} siteMetadata={siteMetadata} />
          ))}
      </div>
    </div>
  )
}

export default Pricing
