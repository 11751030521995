import React from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'

function Testimonial({ testimonial, isCurrent }) {
  return (
    <div
      className={classNames('testimonial', {
        ['testimonial--hidden']: !isCurrent
      })}
    >
      <h3>{testimonial.title}</h3>
      <p className="testimonial__text">{testimonial.text}</p>
      <div className="testimonial__store">
        <img
          className="testimonial__store-image"
          src={testimonial.storeImage}
          alt={testimonial.store}
        />
        <div className="testimonial__author-info">
          <span>{testimonial.store}</span>
          <span>{testimonial.authorPosition}</span>
        </div>
      </div>
    </div>
  )
}

function Testimonials() {
  const [currentTestimonial, setCurrentTestimonial] = React.useState(null)
  const { allTestimonialsJson } = useStaticQuery(
    graphql`
      query {
        allTestimonialsJson {
          edges {
            node {
              enabled
              title
              text
              store
              storeImage
              authorPosition
              rating
            }
          }
        }
      }
    `
  )

  const testimonials = allTestimonialsJson.edges
    .filter(({ node }) => node.enabled)
    .map(({ node }) => node)

  React.useEffect(() => {
    setCurrentTestimonial(testimonials[0])
  }, [])

  const switchTestimonial = delta => {
    const currentIndex = testimonials.indexOf(currentTestimonial)
    const nextIndex = Math.min(
      Math.max(0, currentIndex + delta),
      testimonials.length - 1
    )

    setCurrentTestimonial(testimonials[nextIndex])
  }

  return (
    <div id="testimonials" className={'testimonials-wrap'}>
      <h2>Customer Reviews</h2>
      <div className={'testimonials'}>
        <button
          className="testimonial-switch"
          disabled={testimonials.indexOf(currentTestimonial) === 0}
          onClick={() => switchTestimonial(-1)}
        >
          {'<'}
        </button>
        <div className={'testimonial-wrap'}>
          {testimonials.map(testimonial => (
            <Testimonial
              testimonial={testimonial}
              key={testimonial.title}
              isCurrent={testimonial === currentTestimonial}
            />
          ))}
        </div>
        <button
          className="testimonial-switch"
          disabled={
            testimonials.indexOf(currentTestimonial) === testimonials.length - 1
          }
          onClick={() => switchTestimonial(1)}
        >
          {'>'}
        </button>
      </div>
    </div>
  )
}

export default Testimonials
