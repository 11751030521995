import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

function Testimonials() {
  const { allCustomersJson, allFile } = useStaticQuery(
    graphql`
      query {
        allCustomersJson {
          edges {
            node {
              name
              logo
            }
          }
        }
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              extension
              publicURL
              name
            }
          }
        }
      }
    `
  )

  const customers = allCustomersJson.edges.map(({ node }) => node)
  const images = allFile.edges.map(({ node }) => node)

  return (
    <div id="customers" className={'customers-wrap'}>
      <h2>Served 5k+ Shopify Stores</h2>
      <div className={'customer-list'}>
        {customers.map(customer => {
          const logo =
            customer.logo.indexOf('http') !== -1
              ? customer.logo
              : images.filter(
                  img => `${img.name}.${img.extension}` === customer.logo
                )[0].publicURL

          return (
            <div className={'customer'} key={customer.name}>
              <img className={'customer-logo'} src={logo} alt={customer.name} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Testimonials
