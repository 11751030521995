import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Customers from '../components/customers'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Pricing from '../components/pricing'
import Testimonials from '../components/testimonials'
import featureImage from '../../static/images/hero.svg'

const IndexPage = () => {
  const {
    site: { siteMetadata },
    allFeaturesJson,
    allFile
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            shopifyUrl
          }
        }
        allFeaturesJson {
          edges {
            node {
              id
              title
              description
              image
              imageAlt
            }
          }
        }
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              extension
              publicURL
              name
            }
          }
        }
      }
    `
  )

  const features = allFeaturesJson.edges.map(({ node }) => node)
  const images = allFile.edges.map(({ node }) => node)

  return (
    <Layout>
      <SEO title="Personalized Recommendations" />
      <div className={'page-header home'}>
        <h1>Increase Revenue by showing the right Product</h1>
        <p>
          We are the assistant your store never had. We find the right product
          to show to your customers.
          <br /> Delight your customers and increase sales.
        </p>
        <img alt={'Dashboard'} src={featureImage} />
      </div>
      <div className={'call-to-action'}>
        <div className={'container'}>
          <div className={'call-to-action__content'}>
            <h2>We help your business reach the next level</h2>
            <p>
              Utilize the compelte power of your data and grow your business.
              Increase your revenue with minimum effort. Relax and let our
              algorithms do all the work for you.
            </p>
          </div>

          <div className={'button'}>
            <a href={siteMetadata.shopifyUrl} target={'_blank'}>
              Install For Free
            </a>
          </div>
        </div>
      </div>
      <div className={'container'}>
        <div className={'features'}>
          {features.map((feature, index) => {
            const image = images.filter(
              img => `${img.name}.${img.extension}` === feature.image
            )[0]

            const thumbnail = (
              <div className={'col-6 first'}>
                <div className={'thumbnail'}>
                  <img alt={feature.imageAlt} src={image.publicURL} />
                </div>
              </div>
            )

            const content = (
              <div className={'col-6'}>
                <div className={'feature__content'}>
                  <h2>{feature.title}</h2>
                  <p>{feature.description}</p>
                </div>
              </div>
            )

            const row =
              index % 2 === 0 ? (
                <div className={'row'}>
                  {thumbnail}
                  {content}
                </div>
              ) : (
                <div className={'row'}>
                  {content}
                  {thumbnail}
                </div>
              )

            return (
              <div className={'feature__item'} key={feature.title}>
                {row}
              </div>
            )
          })}
        </div>
      </div>
      <div className={'call-to-action'}>
        <Pricing />
      </div>
      <Testimonials />
      <div className={'secondary-section'}>
        <Customers />
      </div>
    </Layout>
  )
}

export default IndexPage
